html {
  font-family: vulf-sans, system-ui, sans-serif;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

body {
  min-height: 100%;
  display: flex;

}

#__next {
  width: 100%;
}

.scrollbar-none {
  scrollbar-width: 'none';
}

.scrollbar-none::-webkit-scrollbar {
  display: none;
}

/*
  SingleDatePicker and DateRangePicker CSS Overrides.
*/

.SingleDatePicker {
  @apply w-full;
  @apply z-20;
}

.SingleDatePicker .SingleDatePickerInput,
.DateRangePicker .DateRangePickerInput {
  @apply bg-transparent w-full;
}

.date-range-picker .DateRangePickerInput {
  @apply flex w-[250px];
}

.SingleDatePicker input.DateInput_input,
.SingleDatePicker input.DateInput_input[type='text'],
.DateRangePicker .DateInput_input {
  @apply text-sm text-gray-800 font-normal px-0 py-0 border-none bg-transparent w-full;
}

.SingleDatePicker .DateInput,
.DateRangePicker .DateInput {
  @apply overflow-hidden bg-transparent w-full flex;
}

.DateInput_fang {
  @apply hidden;
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  @apply -mt-4;
}

.SingleDatePicker_picker .DayPicker__withBorder,
.DayPicker__withBorder {
  @apply shadow-none border-none;
}

.SingleDatePicker_picker,
.DateRangePicker_picker {
  @apply shadow-md;
}

.SingleDatePicker_picker .DayPickerNavigation_button,
.DayPickerNavigation_button {
  @apply top-6 border-none;
}

.SingleDatePicker_picker .DayPickerNavigation_button:after,
.DayPickerNavigation_button:after {
  content: '';
  @apply inline absolute top-0 left-0 w-3 h-3 border-solid border-deep-space-500;
}

.SingleDatePicker_picker .DayPickerNavigation_button:focus,
.DayPickerNavigation_button:focus {
  @apply outline-none bg-white;
}

.SingleDatePicker_picker .DayPickerNavigation_button:hover,
.DayPickerNavigation_button:hover {
  @apply border-none;
}

.SingleDatePicker_picker .DayPickerNavigation_button svg,
.DayPickerNavigation_button svg {
  @apply hidden;
}

.DayPickerNavigation.DayPickerNavigation__vertical
  .DayPickerNavigation_button
  svg {
  @apply inline;
}

.SingleDatePicker_picker .DayPickerNavigation {
  @apply float-right;
}

.SingleDatePicker_picker .DayPickerNavigation_leftButton__horizontalDefault {
  @apply transform top-6 -left-20 -rotate-45;
}

.DayPickerNavigation_leftButton__horizontalDefault {
  @apply transform top-6 -rotate-45;
}

.SingleDatePicker_picker
  .DayPickerNavigation_leftButton__horizontalDefault:after,
.DayPickerNavigation_leftButton__horizontalDefault:after {
  @apply border-t border-l;
}

.SingleDatePicker_picker
  .DayPickerNavigation_rightButton__horizontalDefault:after,
.DayPickerNavigation_rightButton__horizontalDefault:after {
  @apply border-r border-b;
}

.SingleDatePicker_picker .DayPickerNavigation_rightButton__horizontalDefault,
.DayPickerNavigation_rightButton__horizontalDefault {
  @apply transform top-6 right-8 -rotate-45;
}

.SingleDatePicker_picker .CalendarMonth_caption {
  @apply text-left;
}

.SingleDatePicker_picker .DayPicker_weekHeader small,
.DayPicker_weekHeader small {
  @apply text-xs text-neutral-gray-700 font-bold;
}

.SingleDatePicker_picker .CalendarMonth_caption,
.DateRangePicker_picker .CalendarMonth_caption {
  @apply text-base text-gray-800;
}

.DateRangePicker_picker .CalendarMonth_caption strong {
  @apply font-normal text-sm;
}

.SingleDatePicker_picker .CalendarDay {
  @apply border-none text-sm font-sans text-gray-800;
}

.CalendarDay {
  @apply border-none text-sm font-sans text-gray-400;
}

.CalendarDay.CalendarDay__blocked_out_of_range {
  @apply text-gray-200;
}

.SingleDatePicker_picker .CalendarDay:hover,
.CalendarDay:hover {
  @apply border-none rounded-full bg-gray-100 transition;
}

.CalendarDay.CalendarDay__selected:hover {
  @apply bg-deep-space-500 text-white;
}

.CalendarDay.CalendarDay__hovered_span:hover {
  @apply bg-gray-100 text-gray-800;
}

.SingleDatePicker_picker .CalendarDay__selected,
.SingleDatePicker_picker .CalendarDay__hovered_span,
.CalendarDay__selected {
  @apply border-none rounded-full bg-deep-space-500 transition text-white;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  @apply bg-ice-200 text-gray-800 bg-opacity-50;
}

.CalendarDay.CalendarDay__selected_span:hover {
  @apply bg-deep-space-500 text-white;
}

/*
  DateRangePicker CSS Overrides
*/

.DateRangePicker_picker {
  @apply p-4;
  @apply z-40;
  height: 47vh;
  overflow-x: hidden;
  min-height: 500px;
}

@media screen and (max-width: 640px) {
  .DateRangePicker_picker {
    @apply p-0;
  }
}

.date-range-picker.focused-input .DateRangePicker .DateRangePickerInput .DateInput {
  border: solid 1px #ccc;
  padding-right: 4px;
  padding-left: 4px;
  border-radius: 4px;
  background-color: rgba(255,255,255,.2)
}

.DateRangePicker .DateInput,
.DateRangePicker.DateRangePickerInput {
  @apply text-right grow-0 shrink-0 basis-[110px];
}

.DateRangePicker .DateInput .DateInput_input {
  @apply outline-none;
}

.date-range-picker.bg-focus-secondary
  .DateRangePicker
  .DateInput
  .DateInput_input {
  border-bottom: solid 1px rgba(0, 0, 0, 0.25);
  cursor: text;
}

.DateRangePicker .DateInput:first-child .DateInput_input {
  @apply text-right;
}

.DateRangePicker .DateInput:last-child .DateInput_input {
  @apply text-left w-24;
}

.DateRangePicker .DateInput_input {
  @apply px-0 bg-transparent text-black text-sm font-normal cursor-pointer;
}

.DateRangePicker .DateInput_input__focus {
  @apply border-b border-solid border-black;
}

.DateRangePicker .DateRangePickerInput_arrow {
  @apply px-2.5 bg-transparent text-black;
}

/* TODO: Temporary fix */
.DateInput_screenReaderMessage {
  @apply hidden
}

.spinner-border {
  vertical-align: -0.125em;
  border: 1px solid;
  border-right-color: #eee;
}

.fc-col-header-cell-cushion {
  @apply text-black font-medium;
}

.fc-button.fc-button-primary.fc-prev-button,
.fc-button.fc-button-primary.fc-next-button,
.fc-button.fc-button-primary.fc-today-button,
.fc-toolbar-title {
  @apply hidden;
}

.fc-daygrid-event {
  @apply h-2 overflow-hidden pointer-events-none;
}

.fc-daygrid-day-number {
  @apply text-black cursor-pointer;
}

.fc-daygrid-day-number.bg-black {
  @apply text-white flex items-center justify-center font-sans;
}

/* Used to allow labeling on Recharts graphs */
.recharts-wrapper svg {
  overflow: inherit;
}
