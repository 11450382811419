@tailwind components;
@tailwind base;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'vulf-sans';
    font-style: normal;
    font-weight: 200;
    src: url('/fonts/vulf-sans/Vulf_Sans-Light.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Light.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Light.ttf') format('ttf');
  }
  @font-face {
    font-family: 'vulf-sans';
    font-style: italic;
    font-weight: 200;
    src: url('/fonts/vulf-sans/Vulf_Sans-Light_Italic.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Light_Italic.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Light_Italic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'vulf-sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/vulf-sans/Vulf_Sans-Regular.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Regular.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Regular.ttf') format('ttf');
  }
  @font-face {
    font-family: 'vulf-sans';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/vulf-sans/Vulf_Sans-Italic.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Italic.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Italic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'vulf-sans';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/vulf-sans/Vulf_Sans-Medium.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Medium.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Medium.ttf') format('ttf');
  }
  @font-face {
    font-family: 'vulf-sans';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/vulf-sans/Vulf_Sans-Medium_Italic.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Medium_Italic.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Medium_Italic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'vulf-sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/vulf-sans/Vulf_Sans-Bold.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Bold.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Bold.ttf') format('ttf');
  }
  @font-face {
    font-family: 'vulf-sans';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/vulf-sans/Vulf_Sans-Bold_Italic.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Bold_Italic.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Bold_Italic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'vulf-sans';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/vulf-sans/Vulf_Sans-Black.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Black.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Black.ttf') format('ttf');
  }
  @font-face {
    font-family: 'vulf-sans';
    font-style: italic;
    font-weight: 800;
    src: url('/fonts/vulf-sans/Vulf_Sans-Black_Italic.woff') format('woff'),
      url('/fonts/vulf-sans/Vulf_Sans-Black_Italic.woff2') format('woff2'),
      url('/fonts/vulf-sans/Vulf_Sans-Black_Italic.ttf') format('ttf');
  }

  @font-face {
    font-family: 'meek-display';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/meek-display/MeekDisplayv0.2-Medium.otf')
      format('opentype');
  }
  @font-face {
    font-family: 'meek-display';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/meek-display/MeekDisplayv0.2-Bold.otf') format('opentype');
  }
  @font-face {
    font-family: 'meek-display';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/meek-display/MeekDisplayv0.2-Black.otf') format('opentype');
  }
  @font-face {
    font-family: 'meek-display';
    font-style: normal;
    font-weight: 900;
    src: url('/fonts/meek-display/MeekDisplayv0.2-Super.otf') format('opentype');
  }

  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
    stroke-width: inherit;
  }

  .custom-checkbox:checked:after {
    position: absolute;
    top: 2px;
    left: 5px;
    width: 4px;
    height: 9px;
    transform: rotate(45deg);
    border: solid white;
    border-width: 0 2px 2px 0;
    color: white;
    content: '';
  }
}
