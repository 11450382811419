.analytics-graph .graph .axis-roa .domain,
.analytics-graph .graph .axis-x .domain,
.analytics-graph .graph .axis-x-2 .domain,
.analytics-graph .graph .axis-y .domain,
.analytics-graph .graph .grid-lines .domain {
  display: none;
}
.analytics-graph .graph .axis-y .tick {
  display: none;
}
.analytics-graph .graph .axis-roa .tick {
  font-size: 16px;
  font-weight: 600;
}
.analytics-graph .graph .roa-label {
  font-weight: 700;
}
.analytics-graph .graph .axis-x .tick text,
.analytics-graph .graph .axis-x-2 .tick text {
  font-size: 14px;
  text-transform: uppercase;
  fill: #969595;
}

.analytics-graph .graph .data-labels .subvalue-label {
  @apply text-xs font-normal;
  text-anchor: middle;
}

.analytics-graph .graph .data-labels .value-label,
.analytics-graph .graph .data-labels .projected-label {
  @apply text-sm font-semibold;
  text-anchor: middle;
}

@screen sm {
  .analytics-graph .graph .data-labels .subvalue-label {
    @apply text-sm font-normal;
  }
}

@screen sm {
  .analytics-graph .graph .data-labels .value-label,
  .analytics-graph .graph .data-labels .projected-label {
    @apply text-base font-medium;
  }
}

.analytics-graph .graph .grid-lines .tick {
  opacity: 0.08;
}
.analytics-graph .graph .grid-lines .tick:nth-child(odd) {
  display: none;
}

.analytics-graph .graph-tooltip {
  display: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 8px 10px;
  background-color: #fff;
  text-align: center;
  box-shadow: 0 2px 10px 0 rgb(0 0 0 / 15%);
}

.analytics-graph .graph-tooltip .date {
  @apply text-gray-800 text-sm font-medium;
}
.analytics-graph .graph-tooltip .value {
  @apply text-gray-700 text-base font-semibold;
}

.earnings-graph .graph .axis-roa .domain,
.links-graph .graph .axis-roa .domain,
.earnings-graph .graph .axis-x .domain,
.links-graph .graph .axis-x .domain,
.earnings-graph .graph .axis-x-2 .domain,
.links-graph .graph .axis-x-2 .domain,
.earnings-graph .graph .axis-y .domain,
.links-graph .graph .axis-y .domain,
.earnings-graph .graph .grid-lines .domain,
.links-graph .graph .grid-lines .domain {
  display: none;
}
.earnings-graph .graph .axis-y .tick,
.links-graph .graph .axis-y .tick {
  display: none;
}
.earnings-graph .graph .axis-roa .tick,
.links-graph .graph .axis-roa .tick {
  @apply text-base font-semibold;
}
.earnings-graph .graph .roa-label,
.links-graph .graph .roa-label {
  font-weight: bold;
}
.earnings-graph .graph .axis-x .tick text,
.links-graph .graph .axis-x .tick text,
.earnings-graph .graph .axis-x-2 .tick text,
.links-graph .graph .axis-x-2 .tick text {
  text-transform: uppercase;
  fill: #777;
}
.earnings-graph .graph .data-labels .value-label,
.earnings-graph .graph .data-labels .subvalue-label,
.links-graph .graph .data-labels .value-label,
.earnings-graph .graph .data-labels .projected-label,
.links-graph .graph .data-labels .projected-label {
  text-anchor: middle;
}
.earnings-graph .graph .grid-lines .tick,
.links-graph .graph .grid-lines .tick {
  opacity: 0.08;
}
.earnings-graph .graph .grid-lines .tick:nth-child(odd),
.links-graph .graph .grid-lines .tick:nth-child(odd) {
  display: none;
}
.earnings-graph .graph-tooltip,
.links-graph .graph-tooltip {
  display: none;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  width: 131px;
  padding: 8px 10px;
  background-color: white;
  text-align: center;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
}
.earnings-graph .graph-tooltip .date,
.links-graph .graph-tooltip .date {
  @apply text-gray-800 text-sm font-medium;
}
.earnings-graph .graph-tooltip .value,
.links-graph .graph-tooltip .value {
  @apply text-gray-700 text-base font-semibold;
}
.earnings-graph .graph-tooltip .pacing,
.links-graph .graph-tooltip .pacing {
  @apply text-base font-semibold;
}
.earnings-graph .graph-tooltip.large,
.links-graph .graph-tooltip.large {
  width: 206px;
  height: 81px;
  padding: 8px 15px;
}
.earnings-graph .graph-tooltip.large .date,
.links-graph .graph-tooltip.large .date {
  text-align: left;
}
.earnings-graph .graph-tooltip.large .pacing,
.links-graph .graph-tooltip.large .pacing,
.earnings-graph .graph-tooltip.large .value,
.links-graph .graph-tooltip.large .value {
  display: flex;
  justify-content: space-between;
}
.earnings-graph .graph-tooltip.large .pacing .label,
.links-graph .graph-tooltip.large .pacing .label,
.earnings-graph .graph-tooltip.large .value .label,
.links-graph .graph-tooltip.large .value .label {
  font-size: 14px;
  font-weight: 400;
}
.earnings-graph .graph-tooltip:after,
.links-graph .graph-tooltip:after {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -8px;
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  content: ' ';
}
.earnings-graph .graph-tooltip.arrow-right:after,
.links-graph .graph-tooltip.arrow-right:after {
  left: 80%;
}
.earnings-graph .graph-tooltip.arrow-right.large:after,
.links-graph .graph-tooltip.arrow-right.large:after {
  left: 90%;
}
.earnings-graph .loader-container {
  top: 200px;
}
.earnings-graph .no-result {
  top: 100px;
}

.video-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom:0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.video-container iframe {
  width: 100vw;
  height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
  min-height: 100vh;
  min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-container:after {
  content: '';
  z-index: 1;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
}

/* customize react-month-picker */

.rmp-pad {
  padding-left: 25px !important;
  padding-right: 25px !important;
  padding-bottom: 25px !important;
}

.rmp-popup label {
  color: #1A1A1A !important;
  font-weight: 500;
}

.rmp-tab:not(.disable) {
  color: #412BC9 !important;
}

.rmp-btn {
  border-radius: 8px !important;
  color: #333;
  font-weight: 400 !important;
  width: 25% !important;
}

.rmp-btn.disable {
  color: #bbb;
}

.rmp-btn:not(.active) {
  border: 1px solid transparent !important;
}

.rmp-btn.active {
  background-color: #F2F0FF !important;
  border-color: #412BC9 !important;
  border-width: 1px;
  color: #412BC9 !important;
}

.rmp-btn:hover {
  border-color: #412BC9 !important;
}

.month-picker input {
  background-image: url(/images/icons/calendar.svg);
  background-repeat: no-repeat;
  background-position: right 5% bottom 50%;
}

.recharts-cartesian-grid-horizontal line{
  stroke: #e5e7eb;
}

.recharts-cartesian-axis-tick {
  @apply text-xs;
}

.recharts-label-list svg {
  @apply z-30 absolute;
}

.recharts-cartesian-axis-line{
  stroke: #e5e7eb;
}

.recharts-layer .recharts-label-list {
  @apply text-sm fill-white;
}

.month-picker {
  position: relative; }
.month-picker > .rmp-container {
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 100;
  top: 1px;
  left: -10000px;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container {
    position: fixed;
    top: 0;
    left: -10000px;
    width: 100%;
    height: 100%;
    transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 450ms; } }
.month-picker > .rmp-container.rmp-table {
  display: table; }
.month-picker > .rmp-container.show {
  left: 0;
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
.month-picker > .rmp-container.show .rmp-overlay {
  left: 0; }
.month-picker > .rmp-container .rmp-overlay {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9;
  top: 0;
  left: -10000px;
  opacity: 1;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  transform: translateZ(0);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms; }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-overlay {
    background-color: rgba(0, 0, 0, 0.25); } }
.month-picker > .rmp-container .rmp-cell {
  display: table-cell;
  vertical-align: middle;
  box-sizing: border-box;
  width: 100%;
  height: 100%; }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-cell {
    vertical-align: bottom; } }
.month-picker > .rmp-container .rmp-popup {
  position: absolute;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  margin: 0 auto;
  z-index: 10;
  font-size: 1.2rem;
  opacity: 0;
  border-radius: 3px;
  padding: 0.4rem;
  box-sizing: content-box; }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup {
    box-sizing: border-box;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: 14.4rem;
    transform: translate3d(0, 14.4rem, 0); }
  .month-picker > .rmp-container .rmp-popup.range {
    height: 28rem;
    transform: translate3d(0, 28rem, 0); }
  .month-picker > .rmp-container .rmp-popup.range .rmp-pad {
    margin-top: 0.4rem; }
  .month-picker > .rmp-container .rmp-popup.range .rmp-pad:first-of-type {
    margin-top: 0; } }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup {
    transform: translate3d(0, -64px, 0);
    top: 0;
    width: 20rem; }
  .month-picker > .rmp-container .rmp-popup.range {
    width: 40.6rem;
    padding: 0.6rem; } }
.month-picker > .rmp-container .rmp-popup.show {
  opacity: 1;
  transform: translate3d(0, 0, 0); }
.month-picker > .rmp-container .rmp-popup:after {
  content: ' ';
  clear: both;
  display: table; }
.month-picker > .rmp-container .rmp-popup .rmp-pad {
  position: relative; }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad {
    box-sizing: border-box;
    float: left;
    width: 20rem; }
  .month-picker > .rmp-container .rmp-popup .rmp-pad:nth-of-type(2) {
    float: right; } }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label {
  display: block;
  font-size: 1.4rem;
  text-align: center;
  line-height: 3.4rem; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div label b {
  font-weight: normal;
  margin-right: 0.5em; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i {
  font-style: normal;
  text-align: center;
  width: 3.4rem;
  height: 3.4rem;
  line-height: 3.4rem;
  position: absolute;
  top: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.prev {
  left: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad > div i.next {
  right: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul, .month-picker > .rmp-container .rmp-popup .rmp-pad li {
  list-style-type: none;
  margin: 0;
  padding: 0; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul {
  display: block;
  width: 100%; }
.month-picker > .rmp-container .rmp-popup .rmp-pad ul:after {
  content: ' ';
  clear: both;
  display: table; }
.month-picker > .rmp-container .rmp-popup .rmp-pad li {
  display: block;
  float: left;
  text-align: center;
  font-size: 1.15rem;
  border-radius: 3px;
  line-height: 3.3rem;
  box-sizing: border-box;
  padding: 0.05rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  *white-space: nowrap;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out; }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 25%; } }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup .rmp-pad li {
    width: 33.3333333333%; } }
.month-picker > .rmp-container .rmp-popup .rmp-pad li.multiple {
  background-clip: content-box; }
.month-picker > .rmp-container .rmp-popup .rmp-pad li.range {
  border-radius: 1px; }
.month-picker > .rmp-container .rmp-popup.light {
  color: #666;
  background-color: rgba(255, 255, 255, 0.96); }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border-top: 1px solid #ccc;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.08); }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    border-top: 1px solid rgba(204, 204, 204, 0.5); }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad:first-of-type {
    border-top: 0; } }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light {
    border: 1px solid #ccc;
    box-shadow: 0 1px 5px #ddd; }
  .month-picker > .rmp-container .rmp-popup.light.range .rmp-pad {
    background-color: rgba(238, 238, 238, 0.9); } }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn {
  cursor: pointer;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn:hover {
    background-color: rgba(255, 227, 160, 0.59); } }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .rmp-btn.select {
  background-color: #d3d3d3; }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.active:hover {
  background-color: rgba(31, 42, 58, 0.73);
  color: white; }
.month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.light .rmp-pad li.disable:hover {
  background-color: transparent;
  color: #bbb;
  cursor: default; }
.month-picker > .rmp-container .rmp-popup.dark {
  color: #fff;
  background-color: rgba(50, 50, 50, 0.96); }
@media screen and (max-width: 767px) {
  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
    border-top: 1px solid rgba(113, 113, 113, 0.41); }
  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad:first-of-type {
    border-top: 0; } }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.dark.range .rmp-pad {
    background-color: rgba(70, 70, 70, 0.9); } }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn {
  cursor: pointer;
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }
@media screen and (min-width: 768px) {
  .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn:hover {
    background-color: rgba(255, 210, 96, 0.33); } }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .rmp-btn.select {
  background-color: #262828; }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.active:hover {
  background-color: rgba(189, 211, 242, 0.7);
  color: #303030; }
.month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad .disable:hover, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable, .month-picker > .rmp-container .rmp-popup.dark .rmp-pad li.disable:hover {
  background-color: transparent;
  color: #717171;
  cursor: default; }